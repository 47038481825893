<template>
  <div class="group-wizard-message-settings">
    <v-form data-vv-scope="message-settings">
      <v-flex
        xs6
        class="pr-5"
      >
        <v-layout
          row
          wrap
        >
          <v-flex
            xs12
            class="mb-5"
          >
            <tooltip name="EmailSenderAddress">
              <div class="sw-h5">
                {{ $tc("group_wizard.email_sender_address") }}
              </div>
            </tooltip>
            <v-text-field
              v-model="group.email_sender_address"
              :disabled="!Boolean(emailSenderDomains)"
              v-validate.disable="emailSenderDomainsValidationRules"
              data-vv-name="email_sender_address"
              :data-vv-as="$tc('group_wizard.email_sender_address')"
              :placeholder="
                $tc('group_wizard.email_sender_address_placeholder')
              "
            ></v-text-field>
            <ErrorMessages
              :error-messages="
                errors.collect('message-settings.email_sender_address')
              "
            />
          </v-flex>
          <v-flex
            xs12
            class="mb-5"
          >
            <div class="mb-2 sw-h5">
              {{ $tc("group_wizard.email_sender_name") }}
            </div>
            <div class="sw-caption">
              {{ $t("group_wizard.email_sender_name_caption") }}
            </div>
            <v-text-field
              v-model="group.email_sender_name"
              v-validate.disable="'required|max:100'"
              data-vv-name="email_sender_name"
              :data-vv-as="$tc('group_wizard.email_sender_name')"
              :placeholder="$tc('group_wizard.email_sender_name_placeholder')"
              hide-details
            ></v-text-field>
            <ErrorMessages
              :error-messages="
                errors.collect('message-settings.email_sender_name')
              "
            />
          </v-flex>
          <v-flex xs12>
            <div class="mb-2 sw-h5">
              {{ $tc("group_wizard.sms_sender_name") }}
            </div>
            <div class="sw-caption">
              {{ $t("group_wizard.sms_sender_name_caption") }}
            </div>
            <v-text-field
              v-model="group.sms_sender_name"
              v-validate.disable="'required|max:11'"
              data-vv-name="sms_sender_name"
              :data-vv-as="$tc('group_wizard.sms_sender_name')"
              :placeholder="$tc('group_wizard.sms_sender_name_placeholder')"
              :maxlength="11"
              :counter="11"
              hide-details
            ></v-text-field>
            <ErrorMessages
              :error-messages="
                errors.collect('message-settings.sms_sender_name')
              "
            />
          </v-flex>
        </v-layout>
      </v-flex>
    </v-form>
  </div>
</template>

<script>
export default {
  inject: ["parentValidator"],
  computed: {
    emailSenderAddress() {
      return process.env.VUE_APP_EMAIL_SENDER_ADDRESS || "";
    },
    emailSenderAddressDomain() {
      const email = this.emailSenderAddress.split("@");

      return email[1] || "";
    },
    emailSenderDomains() {
      const domains =
        this.$store.getters.currentUser.email_sender_domains || "";
      const arrayOfDomains = domains.split(",").map((domain) => domain.trim());

      if (!arrayOfDomains.includes(this.emailSenderAddressDomain)) {
        arrayOfDomains.push(this.emailSenderAddressDomain);
      }

      return arrayOfDomains.toString();
    },
    emailSenderDomainsValidationRules() {
      return `required|email|allowed_domains:${this.emailSenderDomains}`;
    },
    group: {
      get() {
        return this.$store.getters.groupWizardGroup;
      },
      set(v) {
        this.$store.dispatch("setGroupWizardGroup", v);
      },
    },
  },
  created() {
    this.$validator = this.parentValidator;
  },
};
</script>

<style lang="scss" scoped></style>
